import axios from "axios"
const http=axios.create({
    baseURL:"https://jcmk.zqzzy.com/prod-api",
    timeout:5000
})
//请求拦截器
http.interceptors.request.use(function(config){
    config.headers.Authorization=localStorage.getItem('token')
    return config
},function(error){
    return Promise.reject(error)
})

//响应拦截器
http.interceptors.request.use(function(response){
    return response
},function(error){
    return Promise.reject(error)
});
//导出
export default http
