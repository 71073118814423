import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    meta: {
      title: '章丘智造服务云平台',
      icon:'../assets/20230714160703.ico'
    }
  },
  // {
  //   path: '/about',
  //   name: 'about',
  //   component: () => import( '../views/AboutView.vue')
  // },
  {
    path: '/detail',
    name: 'detail',
    component: () => import( '../views/DetailView.vue'),
    meta: {
      title: '章丘智造服务云平台',
      icon:'../assets/20230714160703.ico'
    }
  },
  {
    path: '/publish',
    name: 'publish',
    component: () => import( '../views/PublishView.vue'),
    meta: {
      title: '章丘智造服务云平台',
      icon:'../assets/20230714160703.ico'
    }
  },
  {
    path: '/me',
    name: 'me',
    component: () => import( '../views/MeView.vue'),
    meta: {
      title: '章丘智造服务云平台',
      icon:'../assets/20230714160703.ico'
    }
  },
  {
    path: '/login',
    name: 'login',
    component: () => import( '../views/LoginView.vue'),
    meta: {
      title: '章丘智造服务云平台',
      icon:'../assets/20230714160703.ico'
    }
  },
  {
    path: '/form',
    name: 'form',
    component: () => import( '../views/FormView.vue'),
    meta: {
      title: '章丘智造服务云平台',
      icon:'../assets/20230714160703.ico'
    }
  },
  {
    path: '/up',
    name: 'up',
    component: () => import( '../views/upView.vue')
  }
]


const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

// router.beforeEach((to, from, next) => {
//   if (sessionStorage.getItem('token')) {
//     next();
//   }else{
//     next('/login')
//   }
// });


export default router
