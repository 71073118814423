

import request from '@/utils/request' // 引入封装的request.js文件
import request1 from '@/utils/request1' // 引入封装的request.js文件

// const baseURL = 'http://192.168.0.68:8080'; // 设置环境接口的域名

// 成功查询集采商品列表

export function list(params) {
    return request({
        url: `zq/admin/material/list`,
        method: 'get',
        params
    })
}
export function lists(params) {
    return request({
        url: `zq/material/list`,
        method: 'get',
        params
    })
}
//查询集采商品详情成功
export function details(id) {
    return request({
        url:`/zq/material/get/${id}`,
        method: 'get',
    })
}
export function userList(params) {
    return request1({
        url:`common/userList?param=`+params,
        method: 'post'
    })
}

//保存集采商品400
export function save1(params){
    return request({
        url:`/zq/material/save`,
        method:'post',
        data: params
    })
}
export function save(params){
    return request({
        url:`/zq/admin/material/list`,
        method:'get',
        params
    })
}
export function DataDelete(params){
    return request({
        url:`/zq/admin/material/`+params,
        method:'delete',
    })
}

// 报名列表成功
export function application(query){
    return request({
        url:`/zq/material/sign/list?pageSize=`+query.pageSize+'&pageNum='+query.pageNum+'&materialId='+query.id,
        method:'get',
    })
}

// export function application(params){
//     return request({
//         url:`/zq/material/sign/list?pageNum={pageNum}&pageSize={pageSize}`,
//         method:'get',
//         params
//     })
// }


// 我要报名成功
export function add(body){
    return request({
        url:`/zq/material/sign/save`,
        method:'post',
        data:body
    })
}


// 文件上传成功
export function update(body){
    return request({
        url:`/zq/upload`,
        method:'post',
        data:body
    })
}




export function user(body){
    return request({
        url:`/zq/material/list`,
        method:'get',
        data:body
    })
}

// 查看报名列表
export function look(query){
    return request({
        url:`/zq/admin/material/sign/list`,
        method:'get',
        query
    })
}

// 登录
export function login(body){
    return request({
        url:`/zq/auth/login`,
        method:'post',
        data:body
    })
}
// 登录
export function PUTzq(body){
    return request({
        url:`/zq/admin/material`,
        method:'put',
        data:body
    })
}
