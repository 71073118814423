<template>
  <div class="zq">
    <!-- 顶部 -->
    <!-- <Top></Top> -->
    <!-- banner图 -->
    <div class="jc-banner">
      <div class="banner">
        <img src="../assets/banner.png" alt="" />
        <div class="jc-tit"><h1>集采大厅</h1></div>
      </div>
    </div>
    <!-- 市场分类 -->
    <!-- <div class="tabs">
      <div class="tab">
        <span
          v-for="(item, index) in tabList"
          :key="index"
          :class="{ active: cur == index }"
          @click="on(index)"
          >{{ item.name }}</span
        >
      </div>
    </div> -->
    <!-- <div v-show="cur == 0">
      <about></about>
    </div> -->
    <div class="dt">
      <dating></dating>
    </div>
    
  </div>
</template>

<script>
import Top from "../components/Top.vue";
import About from "@/components/about.vue";
import Dating from "@/components/dating.vue";
export default {
  components: {
    Top,
    About,
    Dating,
  },
  data() {
    return {
      cur: 3,
      tabList: [
        {
          name: "供给市场",
        },
        {
          name: "需求市场",
        },
        {
          name: "供需管理",
        },
        {
          name: "集采大厅",
        },
      ],
      list: [
        {
          name: "全部",
        },
        {
          name: "工具消耗",
        },

        {
          name: "安全防护",
        },
        {
          name: "物料搬运",
        },
        {
          name: "储蓄包装",
        },
        {
          name: "测量检测",
        },
        {
          name: "工厂自动化",
        },
        {
          name: "机电产品",
        },
        {
          name: "清洁设备",
        },
        {
          name: "雨管阀",
        },
        {
          name: "气动系统",
        },
        {
          name: "液压系统",
        },
        {
          name: "精密件",
        },
        {
          name: "照明",
        },
        {
          name: "行政办公",
        },
        {
          name: "实验室用品",
        },
        {
          name: "化学品",
        },
        {
          name: "焊接切割",
        },
      ],
      labeList: [
        {
          name: "全部",
        },
        {
          name: "建筑材料",
        },
        {
          name: "作业保护",
        },
        {
          name: "存储物料",
        },
        {
          name: "工业检测",
        },
        {
          name: "五金工具",
        },
        {
          name: "照明灯具",
        },
        {
          name: "绝缘材料",
        },
        {
          name: "实验材料",
        },
        {
          name: "化学物料",
        },
      ],
    };
  },
  methods: {
    on(index) {
      this.cur = index;
      if(index==0){
        window.open("https://zqgydnqyd.wanvdata.com/ent/ent-industrial-product/ent-supply-demand-market")
      }else if(index==1){
        window.open("https://zqgydnqyd.wanvdata.com/ent/ent-industrial-product/ent-supply-demand-marketX")
      }else if(index==2){
        window.open("https://zqgydnqyd.wanvdata.com/ent/ent-industrial-product/ent-supply-demand-manage")
      }else if(index == 3){
          location.reload()
      }
    },
    // getlication() {
    //   // console.log(this.bodyParams);
    //   save(this.bodyParams).then((res) => {
    //     console.log(res, "000");
    //   });
    // },
  },
  created() {
    console.log(window.location.href)
    // this.getlication();
  },
};
</script>

<style lang="scss">
.jc-banner {
  width: 100%;
  height: 240px;
  position: relative;
  .banner {
    width: 100%;
    height: 240px;
    background-color: pink;

    img {
      width: 100%;
      height: 100%;
    }
    .jc-tit {
      position: absolute;
      top: 80px;
      left: 400px;
      font-family: 楷体;
      font-size: 26px;
    }
  }
}
.zq {
  width: 100%;
  height: 100%;
  overflow: hidden;
  .tabs {
    width: 100%;
    height: 40px;
    line-height: 40px;
    display: flex;
    background-color: #fff;
    padding-left: 8%;
    cursor: pointer;
    .tab {
      margin-left: 76px;
      font-size: 14px;
      span {
        padding: 10px 10px;
        text-align: center;
      }
    }
  }
  // .all {
  //   width: 100%;
  //   height: 100%;
  //   font-size: 14px;
  //   display: flex;
  //   margin-left: 130px;
  //   margin-top: 20px;
  //   .box {
  //     width: 18%;
  //     height: 330px;
  //     // background-color: powderblue;
  //     padding-left: 35px;
  //     p {
  //       margin-top: 10px;
  //     }
  //     .footer {
  //       width: 100%;
  //       height: 30px;
  //       display: flex;
  //       justify-content: space-around;
  //       margin-top: 15px;
  //       .right-footer {
  //         color: #4f84f3;
  //       }
  //     }
  //     img {
  //       width: 75%;
  //       height: 120px;
  //     }
  //     .p2 {
  //       color: red;
  //     }
  //     // .p4 {
  //     //   // color: #aaa;
  //     // }
  //     .btn {
  //       width: 80%;
  //       height: 30px;
  //       // background-color: pink;
  //       display: flex;
  //       margin-top: 10px;
  //       .right-btn {
  //         width: 100px;
  //         height: 30px;
  //         border: 1px solid #fdbf86;
  //         color: #fdbf86;
  //         background-color: #fcf4eb;
  //         margin-left: 15px;
  //         border-radius: 5px;
  //       }
  //       .left-btn {
  //         width: 100px;
  //         height: 30px;
  //         border: 1px solid #68c394;
  //         background-color: #e5faf0;
  //         color: #68c394;
  //         border-radius: 5px;
  //       }
  //     }
  //   }
  // }
  // .search {
  //   width: 100%;
  //   height: 40px;
  //   display: flex;
  //   line-height: 40px;
  //   font-size: 14px;
  //   .names {
  //     width: 110px;
  //     height: 40px;
  //     margin-left: 90px;
  //   }
  //   input {
  //     text-indent: 15px;
  //     border: 1px solid #aaa;
  //     border-radius: 3px;
  //     margin-top: 8px;
  //     width: 300px;
  //     height: 25px;
  //   }
  // }
  .labels {
    width: 100%;
    height: 25px;
    margin-bottom: 10px;
    display: flex;
    line-height: 30px;
    font-size: 14px;
    .cplabel {
      width: 80px;
      height: 40px;
      margin-left: 90px;
    }
    .name {
      display: inline-block;
      // margin: 0 14px;
      padding: 1px 14px;
      height: 30px;
      // color: #757575;
    }
  }
  .active {
    padding-bottom: 8px;
    color: #4f84f3;
    border-bottom: 1px solid #4f84f3;
  }
}
</style>
