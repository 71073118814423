import Vue from 'vue'
import App from './App.vue'
import router from './router'
import VueCookies from 'vue-cookies'

router.beforeEach((to,from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title
  }
  next()
})
import store from './store'
// import axios from 'axios'
// 引入element
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
// 引入vant
import Vant from 'vant';
import 'vant/lib/index.css';

Vue.use(Vant)
Vue.use(VueCookies)
Vue.use(ElementUI);
// Vue.prototype.$axios=axios
Vue.config.productionTip = false

new Vue({
  router,
  store,
  el: '#app',
  render: h => h(App)
}).$mount('#app')