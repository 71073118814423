<template>
  <div>
    <div class="dw">
      <div class="types">
      <div class="cptype">产品类型：</div>
      <div class="type">
        <span
          class="title"
          v-for="(item, index) in list"
          :key="index"
          :class="{ actives: isActive == index }"
          @click="onClick(index)"
          >{{ item.name }}</span
        >
      </div>
    </div>
    <div class="labels">
      <div class="cplabel">产品标签：</div>
      <div class="label">
        <span
          class="name"
          v-for="(item, index) in labeList"
          :key="index"
          :class="{ actives: isActives == index }"
          @click="onClicks(index)"
          >{{ item.name }}</span
        >
      </div>
    </div>
    <!-- 搜索 -->
    <div class="search">
      <div class="names">产品/企业名称：</div>
      <input
        type="text"
        placeholder="请输入产品,企业名称"
        v-model="value"
        @input="search"
      />
    </div>

    <div class="all">
      <div class="box" v-for="(item, i) in lists" :key="i">
        <!-- <img src="../assets/b.jpg" alt="" /> -->
        <p class="p1">{{ item.companyName }}</p>
        <p class="p2">{{ item.companyType }}</p>
        <p class="p3">{{ item.minOrderQuantity }}</p>
        <p class="p4">库存：共{{ item.id }}件</p>
        <div class="btn">
          <button class="left-btn">建筑材料</button>
          <button class="right-btn">保护作业</button>
        </div>
        <div class="footer">
          <div class="left-footer">收藏</div>
          <div class="right-footer" @click="godetail(item.id)">查看详情>></div>
        </div>
      </div>


      <!-- 分页 -->
    <div class="block">
      <!-- <span class="demonstration">完整功能</span> -->
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="this.queryParams.pageNum"
        :page-sizes="[10, 16, 24, 32]"
        :page-size="this.queryParams.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="this.total"
      >
      </el-pagination>
    </div>
    </div>
    
    
    </div>

  </div>
</template>

<script>
import { list } from "@/api/api";
export default {
  name: "",
  components: {},
  data() {
    return {
      currentPage4: 4,
      id:0,
      value: "",
      isActives: false,
      isActive: false,
      lists: [], //一个，未调用
      values: [],
      queryParams: {
        pageNum: 1,
        pageSize: 10,
        name: "",
      },
      list: [
        {
          name: "全部",
        },
        {
          name: "工具消耗",
        },
        {
          name: "安全防护",
        },
        {
          name: "物料搬运",
        },
        {
          name: "储蓄包装",
        },
        {
          name: "测量检测",
        },
        {
          name: "工厂自动化",
        },
        {
          name: "机电产品",
        },
        {
          name: "清洁设备",
        },
        {
          name: "雨管阀",
        },
        {
          name: "气动系统",
        },
        {
          name: "液压系统",
        },
        {
          name: "精密件",
        },
        {
          name: "照明",
        },
        {
          name: "行政办公",
        },
        {
          name: "实验室用品",
        },
        {
          name: "化学品",
        },
        {
          name: "焊接切割",
        },
      ],
      labeList: [
        {
          name: "全部",
        },
        {
          name: "建筑材料",
        },
        {
          name: "作业保护",
        },
        {
          name: "存储物料",
        },
        {
          name: "工业检测",
        },
        {
          name: "五金工具",
        },
        {
          name: "照明灯具",
        },
        {
          name: "绝缘材料",
        },
        {
          name: "实验材料",
        },
        {
          name: "化学物料",
        },
      ],
      total:0
    };
  },
  created() {
    this.getlist();
  },
  computed: {},
  methods: {
    // 分页
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.queryParams.pageSize=val
      this.getlist()
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.queryParams.pageNum=val
      this.getlist()
    },
    // 搜索
    search() {
      this.lists = this.values.filter((item) => {
        return (
          item.companyName.includes(this.value) ||
          item.name.includes(this.value)
        );
      });
    },

    godetail(id) {
      this.$router.push({path:'/detail',query:{id:id}});
    },
    // 数据渲染
    getlist() {
      list(this.queryParams).then((res) => {
        this.lists = res.data.rows;
        this.values = res.data.rows;
        this.total=res.data.total
        console.log(this.lists);
        console.log(this.values);
      });
    },
    onClick(index) {
      this.isActive = index;
    },
    onClicks(index) {
      this.isActives = index;
    },
  },
};
</script>
<style scoped lang="scss">
.dw{


.all {
  width: 100%;
  height: 100vh;
  font-size: 14px;
  // display: flex;
  margin-left: 130px;
  margin-top: 20px;
  // background-color: palegreen;
.block{
  position: fixed;
  bottom: 10px;
  left: 400px;
}
  .box {
    width: 18%;
    height: 280px;
    float: left;
      // background-color: powderblue;
    padding-left: 35px;
    p {
      margin-top: 10px;
    }
    .footer {
      width: 100%;
      height: 30px;
      display: flex;
      justify-content: space-around;
      margin-top: 15px;
      border-top: 1px solid rgba(204, 204, 204, 0.459);
      padding-top: 15px;
      .right-footer {
        color: #4f84f3;
      }
    }
    img {
      width: 75%;
      height: 120px;
    }
    .p2 {
      color: red;
    }
    // .p4 {
    //   // color: #aaa;
    // }
    .btn {
      width: 80%;
      height: 30px;
      // background-color: pink;
      display: flex;
      margin-top: 10px;
      .right-btn {
        width: 100px;
        height: 30px;
        border: 1px solid #fdbf86;
        color: #fdbf86;
        background-color: #fcf4eb;
        margin-left: 15px;
        border-radius: 5px;
      }
      .left-btn {
        width: 100px;
        height: 30px;
        border: 1px solid #68c394;
        background-color: #e5faf0;
        color: #68c394;
        border-radius: 5px;
      }
    }
  }
}
.search {
  width: 100%;
  height: 40px;
  display: flex;
  line-height: 40px;
  font-size: 14px;
  .names {
    width: 110px;
    height: 40px;
    margin-left: 90px;
  }
  input {
    text-indent: 15px;
    border: 1px solid #aaa;
    border-radius: 3px;
    margin-top: 8px;
    width: 300px;
    height: 25px;
  }
}
.types {
  width: 100%;
  height: 60px;
  display: flex;
  font-size: 14px;
  overflow: hidden;
  margin-top: 15px;
  .cptype {
    width: 80px;
    height: 60px;
    margin-left: 90px;
    padding-top: 5px;
  }
  .type {
    width: 70%;
    height: 60px;
  }
  .title {
    display: inline-block;
    padding: 5px 14px;
  }
}
.market {
  width: 100%;
  height: 40px;
  background-color: #f7f7f7;
  line-height: 40px;
  ul {
    width: 40%;
    height: 40px;
    margin-left: 60px;
    display: flex;
    font-size: 14px;
    li {
      margin-left: 30px;
    }
    span {
      color: #417ff1;
      border-bottom: 2px solid #417ff1;
      padding-bottom: 8px;
    }
  }
}

.jc-banner {
  width: 100%;
  height: 400px;
  position: relative;
  .banner {
    width: 100%;
    height: 400px;
    background-color: pink;

    img {
      width: 100%;
      height: 100%;
    }
    .jc-tit {
      // width: 180px;
      // height: 60px;
      position: absolute;
      top: 180px;
      left: 450px;
      font-family: 楷体;
      font-size: 26px;
    }
  }
}
.active {
  background-color: #417ff1;
  color: white;
  border-radius: 5% 5% 5% 5%;
}
.actives {
  background-color: #417ff1;
  color: white;
  border-radius: 5% 5% 5% 5%;
}
}


</style>
