<template>
  <div class="about" v-cloak>
    <div class="box-jc">
      <div class="product-jc">
        <span>集采产品</span>
      </div>
      <div class="release-jc" @click="go">发布集采</div>
      <div class="release-jc" @click="go1">我的发布</div>
    </div>

    <div class="ipt-box">
      <div class="title-box">产品/企业名称：</div>
      <el-input class="input" size="small" v-model="params.name" placeholder="请输入产品，企业名称" clearable></el-input><el-button size="medium" type="primary" icon="el-icon-search" @click="getuser">搜索</el-button>

    </div>

    <div class="view">
     <div class="zuo">
        <div class="list" v-for="(item, index) in list" :key="index">
        <img style="margin: 0 auto;border: none;" gages="center" :src="item.imageFile.url" alt="" v-if="item.imageFile!==null"/>
        <img style="margin: 0 auto;border: none;" gages="center" src="../assets/login-background.jpg" alt="" v-if="item.imageFile==null"/>
        <p class="name">{{item.name}}</p>
        <p class="p304">{{item.description}}</p>
        <p><span class="p3500">目标数量:</span>{{ item.minOrderQuantity }}{{ item.unit }}</p>
        <p>
          <span class="jg">集采价格：</span
          ><span class="red">￥{{ item.price }}</span>
        </p>
        <button class="btn" @click="godetail(item.id)">查看集采</button>
        <div class="fixd"><i class="el-icon-s-data"></i>集采中</div>
      </div>
             <el-pagination class="pagination"
    layout="total, prev, pager, next"
    :page-size="12"
    @current-change="pageCurrentChangeHandle"
    :total="total"
    >
  </el-pagination>
     </div>
 
   

    </div>


  </div>
</template>

<script>
import { lists } from "../api/api";
export default {
  name: "",
  components: {},
  data() {
    return {
      list: [],
      arr: [],
      total: 0,
      value: "",
      id: 0,
      params: {
        pageSize: 12,
        pageNum: 1,
        name: "",
      },
      href: "",
    };
  },
  created() {
    // window.refreshTab = function () {
    //   insTb.reload();
    // };
    // this.$cookies.set("user_href",123);
    // this.$cookies.set("user_href","9e1b7973d2d310cf95eb828c5c6e7bf9");
    this.getuser();
    console.log(window.location.href.split('?')[1])
    // this.href = window.location.href.split('?')[1]
    if (window.location.href.split('?')[1].split('=')[1]){
      
    this.href = window.location.href.split('?')[1].split('=')[1]
    // this.href = '9e1b7973d2d310cf95eb828c5c6e7bf9'
    localStorage.setItem('href', this.href);
    // this.$cookies.set("user_href",this.href);
    }

  },
  mounted() {},
  methods: {
    search() {
      this.list = this.arr.filter((item) => {
        return (
          item.companyName.includes(this.value) ||
          item.name.includes(this.value)
        );
      });
    },
    go() {
      this.$router.push({path:"/publish",query:{key: this.href}});
    },
    go1() {
      this.$router.push({path:"/me",query:{key: this.href}});
    },
    pageCurrentChangeHandle(val){
      this.params.pageNum = val
      this.getuser()
    },
    godetail(id) {
      this.$router.push({ path: "/detail", query: { id: id } });
      console.log(id);
    },
    getuser() {
      lists(this.params).then((res) => {
        console.log(res);
        this.list = res.data.rows;
        this.arr = res.data.rows;
        this.total = res.data.total
        console.log(this.list);
      });
    },
  },
};
</script>
<style lang="scss">

 [v-cloak]{
            display: none;
        }
        .release-jc{
          cursor: pointer;
          margin-right: 30px;
        }
.about {
  width: 100%;
  height: 1200px;
  overflow: hidden;

  .view {
    margin-top: 20px;
    // display: flex;
    padding-left: 168px;
    height: 1200px;
  }
  .p304{
  height: 16px;
  margin-top: 10px;
  overflow: hidden;
text-overflow: ellipsis;
white-space: nowrap;
}

.active{
    border-bottom: none !important;
  }
.name{
  height: 19px;
  margin-top: 10px;
}
 .zuo{
    height: 1100px;
  //  margin-left: 100px;
  width: 100%;
  margin: 0 auto;
   position: relative;
.pagination{
  position: absolute;
  // position: fixed;
  bottom: 40px;
  // left: 35%;
  left: 30%;

}
    .list {
    width: 18%;
    height: 300px;
    float: left;
    cursor: pointer;
    border: 1px solid rgba(233, 231, 231, 0.589);
    // background-color: powderblue;
    padding-left: 1.8%;
    box-sizing: border-box;
    margin-left: 40px;
    margin-top: 15px;
    font-size: 14px;
    position: relative;
    .fixd {
      width: 80px;
      height: 30px;
      line-height: 30px;
      background-color: #e0e7f4e0;
      color: #2b74f1;
      position: absolute;
      font-size: 16px;
      top: 0;
      left: 20;
    }
    img {
      width: 90%;
      height: 120px;
    }
    p {
      margin-top: 10px;
    }
    .p3500 {
      color: #aaa;
      font-size: 12px;
    }
    .p304 {
      color: #aaa;
      font-size: 12px;
    }
    .btn {
      width: 90%;
      height: 30px;
      cursor: pointer;
      background-color: #e7f0ff;
      color: #2b74f1;
      border: none;
      border-radius: 2px;
      margin-top: 15px;
    }
    .jg {
      color: #aaa;
      font-size: 12px;
    }
    .red {
      color: red;
      font-size: 16px;
    }
  }
 }
  .list:hover {
    width: 18%;
    height: 300px;
    float: left;
    // background-color: powderblue;
    margin-left: 40px;
    // padding-top: 5px;
    font-size: 14px;
    position: relative;
    box-shadow: 2px 2px 2px 2px #e0e7f4e0;
    .fixd {
      height: 30px;
      line-height: 30px;
      background-color: #e0e7f4e0;
      color: #2b74f1;
      position: absolute;
      top: 0;
      left: 20;
    }
    img {
      height: 120px;
    }
    p {
      margin-top: 10px;
    }
    .p3500 {
      color: #aaa;
      font-size: 12px;
    }
    .p304 {
      color: #aaa;
      font-size: 12px;
    }
    .btn {
      width: 90%;
      height: 30px;
      background-color: #2b74f1;
      color: #fff;
      border: none;
      border-radius: 2px;
      margin-top: 15px;
    }
    .jg {
      color: #aaa;
      font-size: 12px;
    }
    .red {
      color: red;
      font-size: 16px;
    }
  }
  .ipt-box {
    width: 100%;
    height: 40px;
    line-height: 40px;
    display: flex;
    font-size: 14px;
    padding-left: 13.8%;
    position: relative;
   .search{
     position: absolute;
     top: 2px;
     right:1000px;
     color: rgb(184, 184, 184);
   }
    .input {
      width: 300px;
      height: 25px;
      font-size: 8px;
      margin-right: 20px;
    }
  }
  .box-jc {
    width: 100%;
    height: 40px;
    line-height: 40px;
    display: flex;
    padding-left: 12.5%;
    .product-jc {
      margin-left: 20px;
      margin-right: 40px;

      span {
        color: #1769f1;
        padding-left: 10px;
        border-left: 2px solid #1769f1;
      }
    }
  }
  .tabs {
    width: 100%;
    height: 40px;
    line-height: 40px;
    display: flex;
    background-color: #f7f7f7;
    padding-left: 236px;
    .tab {
      margin-left: 40px;
      font-size: 14px;
    }
  }
}
</style>
